.experience-section {
  width: 100%;
  padding: 0 20px;
  animation: fadeInUp 1s ease-out 1.2s both;
}

.experience-section h2 {
  text-align: left;
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
  font-weight: 700;
  position: relative;
  padding-bottom: 8px;
  color: var(--text-primary);
}

.experience-section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 3px;
  background-color: #61dafb;
}

.experience-grid {
  display: grid;
  gap: 2rem;
  margin-top: 1.5rem;
  /* Default for mobile: 1 column */
  grid-template-columns: 1fr;
}

/* Medium screens (tablets): 2x2 grid */
@media (min-width: 768px) {
  .experience-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Large screens: row of 4 */
@media (min-width: 1024px) {
  .experience-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.experience-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.5rem;
  border-radius: 12px;
  background: var(--card-bg);
  transition: transform 0.2s ease-in-out;
  animation: fadeInUp 1s ease-out both;
  opacity: 0;
}

/* Stagger the animations for each item */
.experience-item:nth-child(1) { animation-delay: 1.3s; }
.experience-item:nth-child(2) { animation-delay: 1.4s; }
.experience-item:nth-child(3) { animation-delay: 1.5s; }
.experience-item:nth-child(4) { animation-delay: 1.6s; }

.experience-item:hover {
  transform: translateY(-5px);
}

.experience-logo {
  width: 120px;
  height: 120px;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.experience-logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.experience-details h3 {
  margin: 0.5rem 0;
  font-size: 1.2rem;
  color: var(--text-primary);
}

.experience-details p {
  margin: 0.25rem 0;
  font-size: 0.95rem;
  color: var(--text-secondary);
  line-height: 1.5;
}

.experience-period {
  font-size: 0.9rem;
  color: var(--text-tertiary);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .experience-section {
    padding: 0 16px;
  }

  .experience-section h2 {
    font-size: 1.5rem;
  }

  .experience-details h3 {
    font-size: 1.1rem;
  }

  .experience-details p {
    font-size: 0.95rem;
  }

  .experience-period {
    font-size: 0.9rem;
  }

  .experience-logo {
    width: 100px;
    height: 100px;
    margin-bottom: 1rem;
  }
}

/* Additional adjustments for very small screens */
@media (max-width: 360px) {
  .experience-section h2 {
    font-size: 2rem;
  }
} 