:root {
  /* Dark theme (default) */
  --primary: #61dafb;
  --background: #282c34;
  --text-primary: #ffffff;
  --text-secondary: rgba(255, 255, 255, 0.9);
  --surface: rgba(97, 218, 251, 0.05);
  --content-padding: 24px;
}

[data-theme='light'] {
  --primary: #0088cc;
  --background: #f5f5f7;
  --text-primary: #2c3e50;
  --text-secondary: #64748b;
  --surface: rgba(0, 136, 204, 0.05);
}

/* Optional: adjust the gradient colors for light mode */
[data-theme='light'] .animated-banner,
[data-theme='light'] .profile-photo {
  background: linear-gradient(-45deg, 
    #ff9a9e,   /* Softer pink */
    #fad0c4,   /* Soft peach */
    #a1c4fd,   /* Soft blue */
    #c2e9fb    /* Light blue */
  );
  background-size: 400% 400%;
}

/* Add theme toggle button styles */
.theme-toggle {
  position: fixed;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.3s ease;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--surface);
  backdrop-filter: blur(8px);
}

.theme-toggle:hover {
  transform: scale(1.1);
}

.theme-toggle svg {
  width: 24px;
  height: 24px;
  color: var(--primary);
}

.App {
  text-align: center;
  background-color: var(--background);
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  position: relative; /* Contain absolute positioned children */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 6.67s linear;
  }
}

.App-header {
  background-color: var(--background);
  min-height: calc(100vh - 10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-primary);
  margin-top: -10px;
}

.App-header::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at top right,
    rgba(97, 218, 251, 0.1),
    transparent 60%
  );
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.profile-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 4px;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
}

.profile-photo img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Remove or comment out this hover effect */
/* .profile-photo:hover {
  transform: scale(1.02);
  filter: brightness(1.1);
} */

.about-section {
  max-width: 800px;
  margin: 0;
  text-align: left;
  padding: 0 20px;
  overflow: visible;
  word-wrap: break-word;
  animation: fadeInUp 1s ease-out 0.8s both;
}

.about-section p {
  margin-bottom: 20px;
  line-height: 1.5;
  color: var(--text-secondary);
}

.contact-links {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin: 1rem 0;
  padding: 0 20px;
}

.contact-link {
  min-width: 120px;
  text-align: center;
  color: #61dafb;
  text-decoration: none;
  padding: 12px 24px;
  border: 2px solid #61dafb;
  border-radius: 8px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  animation: fadeInUp 1s ease-out 1s both;
  background-color: rgba(97, 218, 251, 0.05);
  backdrop-filter: blur(8px);
}

.contact-link:hover {
  background-color: #61dafb;
  color: #282c34;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(97, 218, 251, 0.2);
}

.icon {
  font-size: 1.2rem;
}

.animated-banner {
  width: 100%;
  height: 10px;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  overflow: hidden;
}

.animated-banner:not(.bottom-banner) {
  margin-bottom: 60px;
}

.bottom-banner {
  margin-top: 60px;  /* Add space above the bottom banner */
}

.experience-section {
  margin: 1rem 0 3rem 0;  /* Increased bottom margin */
  padding-bottom: 2rem;  /* Added padding at the bottom */
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.content-wrapper {
  width: 100%;
  max-width: 100%;
  padding: 0 var(--content-padding);
  box-sizing: border-box; /* Include padding in width calculation */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 3.5rem;
  margin: 24px 0 8px;
  font-weight: 700;
  text-align: center;
  animation: fadeInUp 1s ease-out 0.2s both;
  text-shadow: 0 2px 10px rgba(97, 218, 251, 0.2);
}

.subtitle {
  font-size: 1.5rem;
  color: #61dafb;
  margin: 0 0 32px;
  font-weight: 500;
  text-align: center;
  animation: fadeInUp 1s ease-out 0.4s both;
}

.intro {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto 48px;
  color: var(--text-secondary);
  text-align: center;
  animation: fadeInUp 1s ease-out 0.6s both;
}

.about-section h2 {
  position: relative;
  padding-bottom: 8px;
  color: var(--text-primary);
}

.about-section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 3px;
  background-color: #61dafb;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-on-scroll {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.animate-on-scroll.visible {
  opacity: 1;
  transform: translateY(0);
}

.projects-section {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
}

.projects-section h2 {
  margin-bottom: 30px;
  text-align: center;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  justify-items: center;
}

.project-card {
  width: 100%;
  padding: 24px;
  background-color: var(--surface);
  border-radius: 12px;
  transition: all 0.3s ease;
  backdrop-filter: blur(8px);
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.project-card h3 {
  color: var(--primary);
  margin-bottom: 12px;
  font-size: 1.2rem;
}

.project-card p {
  color: var(--text-secondary);
  margin-bottom: 16px;
  font-size: 0.95rem;
  line-height: 1.5;
}

.project-tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.project-tags span {
  padding: 4px 12px;
  background-color: var(--primary);
  color: var(--background);
  border-radius: 16px;
  font-size: 0.8rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
    margin: 0; /* Remove any horizontal margins */
    padding: 0 var(--content-padding);
    width: 100%;
    box-sizing: border-box;
  }

  .project-card {
    width: 100%;
    margin: 0 0 16px 0; /* Vertical margin only */
    box-sizing: border-box;
  }

  .contact-links {
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 0 16px;
    width: 100%;
  }

  .contact-link {
    width: 80%;
    max-width: 280px;
    margin: 0 auto;
  }
}

.company-link {
  display: inline-block;
  text-decoration: none;
  color: #ff79c6;
  position: relative;
  transition: all 0.3s ease;
  padding: 0 4px;
}

[data-theme='light'] .company-link {
  color: #0088cc;
}

.company-link:hover {
  color: #bd93f9;
  text-shadow: 0 0 8px rgba(255, 121, 198, 0.5);
}

.company-link::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, 
    transparent,
    currentColor,
    transparent
  );
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.4s ease;
}

.company-link:hover::before {
  transform: scaleX(1);
}

/* Base responsive adjustments */
@media (max-width: 768px) {
  :root {
    --content-padding: 16px;
  }

  .content-wrapper {
    padding: 0 var(--content-padding);
  }

  .title {
    font-size: 2.5rem;  /* Smaller title on mobile */
    margin: 16px 0 8px;
  }

  .subtitle {
    font-size: 1.2rem;
    margin: 0 0 24px;
  }

  .intro {
    font-size: 1rem;
    margin: 0 auto 32px;
    padding: 0 16px;
  }

  .profile-photo {
    width: 150px;  /* Smaller profile photo on mobile */
    height: 150px;
  }

  .about-section {
    padding: 0 16px;
  }

  .about-section h2 {
    font-size: 1.5rem;
  }

  .about-section p {
    font-size: 0.95rem;
  }

  .contact-links {
    flex-direction: column;  /* Stack buttons on mobile */
    gap: 12px;
    padding: 0 16px;
  }

  .contact-link {
    width: 100%;  /* Full width buttons on mobile */
    max-width: 280px;
  }

  .projects-section {
    padding: 20px 16px;
  }

  .projects-grid {
    grid-template-columns: 1fr;  /* Single column on mobile */
    gap: 16px;
  }

  .project-card {
    padding: 16px;
  }

  .project-card h3 {
    font-size: 1.1rem;
  }

  .project-card p {
    font-size: 0.9rem;
  }

  .project-tags span {
    font-size: 0.75rem;
  }
}

/* Additional adjustments for very small screens */
@media (max-width: 360px) {
  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.1rem;
  }

  .profile-photo {
    width: 120px;
    height: 120px;
  }

  .theme-toggle {
    top: 12px;
    right: 12px;
  }
}

/* Landscape mode adjustments */
@media (max-height: 600px) and (orientation: landscape) {
  .App-header {
    padding: 40px 0;
  }

  .profile-photo {
    width: 100px;
    height: 100px;
    margin: 10px 0;
  }

  .title {
    font-size: 2rem;
    margin: 10px 0 5px;
  }

  .subtitle {
    margin: 0 0 15px;
  }

  .intro {
    margin: 0 auto 20px;
  }
}

/* Tablet optimizations */
@media (min-width: 769px) and (max-width: 1024px) {
  .content-wrapper {
    max-width: 90%;
  }

  .projects-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

/* Ensure content doesn't get too wide on large screens */
@media (min-width: 1400px) {
  .content-wrapper {
    max-width: 1200px;
  }
}

/* Fix viewport overflow issues */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.main-content {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.artificial-societies-section {
  max-width: 800px;
  margin: 1rem 0;
  text-align: left;
  padding: 0 20px;
  overflow: visible;
  word-wrap: break-word;
  animation: fadeInUp 1s ease-out 0.8s both;
}

.artificial-societies-section h2 {
  position: relative;
  padding-bottom: 8px;
  color: var(--text-primary);
  font-size: 2.5rem;
  font-weight: 700;
}

.artificial-societies-section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 3px;
  background-color: #61dafb;
}

.artificial-societies-section p {
  margin-bottom: 20px;
  line-height: 1.5;
  color: var(--text-secondary);
  font-size: 1.2rem;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .artificial-societies-section {
    padding: 0 16px;
  }

  .artificial-societies-section h2 {
    font-size: 1.5rem;
  }

  .artificial-societies-section p {
    font-size: 0.95rem;
  }
}

.experience-section {
  margin: 1rem 0;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .main-content {
    gap: 3rem;
  }
}

.artificial-societies-content {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  margin-top: 2rem;
}

.artificial-societies-content .logo-container {
  flex-shrink: 0;
  width: 200px;
  height: 200px;
  background: #000000;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.2s ease-in-out;
}

.artificial-societies-content .logo-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.artificial-societies-content p {
  flex: 1;
  margin: 0;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .artificial-societies-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1.5rem;
  }

  .artificial-societies-content .logo-container {
    width: 150px;
    height: 150px;
    padding: 1rem;
  }
}

.artificial-societies-content .text-content {
  flex: 1;
  text-align: left;
}

.artificial-societies-content .text-content p {
  margin: 0 0 1.5rem 0;
  line-height: 1.5;
  color: var(--text-secondary);
  font-size: 1.2rem;
  text-align: left;
}

.artificial-societies-content .text-content p:last-child {
  margin-bottom: 0;  /* Remove margin from last paragraph */
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .artificial-societies-content {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .artificial-societies-content .text-content {
    text-align: left;
    width: 100%;
  }

  .artificial-societies-content .text-content p {
    font-size: 0.95rem;
    text-align: left;
  }
}

.logo-link {
  text-decoration: none;
  display: block;
  transition: transform 0.2s ease-in-out;
}

.logo-link:hover {
  transform: translateY(-5px);
}

.logo-link:hover .logo-container {
  box-shadow: 0 4px 12px rgba(97, 218, 251, 0.2);
}

.artificial-societies-content .logo-container {
  flex-shrink: 0;
  width: 200px;
  height: 200px;
  background: #000000;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.2s ease-in-out;
}
